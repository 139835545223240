
/* General */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    /* padding: 50px 0; */
}
#root {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}
.App {
    width: 100%;
    max-width: 800px;
}
.page-content-wrapper {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Header */
.header {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    background: #efefef;
}
.header .logo-wrapper {
    width: 100%;
    max-width: 140px;
    flex: 0 0 auto;
}
.header .logo-wrapper a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    font-family: cursive;
}
.header .navigation {
    width: 100%;
    flex: 1 1 auto;
}
.header .header-button {
    flex: 0 0 auto;
    white-space: nowrap;
    margin-left: 20px;
}
.header .navigation ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: flex-end;
    column-gap: 15px;
}
.header .navigation ul a {
    color: teal;
    text-decoration: none;
}
.navigation-items {
    /* margin-left: 10px; */
}



/* Filters for posts */
.select-filter-wrapper {
    display: flex;
    justify-content: space-between;
}


/* List of posts */
.post-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 2px solid teal;
    margin-top: 15px;
}

.post-item-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.post-item-enter-active {
    opacity: 1;
    transition: all .5s ease-in;
    transform: translateX(0);
}
.post-item-exit {
    opacity: 1;
}
.post-item-exit-active {
    opacity: 0;
    transition: all .5s ease-in;
    transform: translateX(-100%);
}
.post-button {
    display: flex;
    flex: 0 0 auto;
}
.post-button button {
    margin-left: 10px;
}



/* Pagination */
.pagination-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.pagination-wrapper .pagination-item {
    padding: 10px;
    border: 1px solid teal;
    cursor: pointer; 
    margin-right: 5px;
}
.pagination-wrapper .pagination-item.active {
    border-color: orange;
    font-weight: bold;
}




/* Individual Post Page */
.post-comment {
    margin-top: 40px;
}
.post-comment .data-title {
    margin-bottom: 25px;
}
.comment-item {
    margin-bottom: 30px;
}
.comment-item .data-name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}
.comment-item .data-name span {
    font-weight: normal;
    font-size: 16px;
}
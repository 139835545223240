.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px dashed teal;
    animation: rotate 2s infinite linear;
}

@keyframes rotate {
    
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(180deg) scale(1.3);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }

}
.reactModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgba(0, 0, 0, .4);
}
.reactModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}
.reactModalContent {
    padding: 25px;
    background: white;
    border-radius: 16px;
    width: 100%;
    max-width: 500px;
}